:root {
  --brand-primary-color: #1E162B; //brand-primary-dark
  --brand-secondary-color: #3B2473; //brand-primary-medium-dark
  --brand-secondary-light-color: #4F3099; //brand-primary
  --brand-tertiary-color: #ECEAF9; //brand-primary-light
}

/* VARIABLES */
$brand-primary: var(--brand-primary-color); //brand-primary-dark -- header
$brand-secondary: var(--brand-secondary-color); //brand-primary-medium-dark -- nav
$brand-secondary-light: var(--brand-secondary-light-color); //brand-primary
$brand-tertiary: var(--brand-tertiary-color); //brand-primary-light

$brand-secondary-dark-aqua: #0072be;
$brand-secondary-navy: #3A40B6;

$brand-info: $brand-secondary;
$brand-success: #40a458;
$brand-success-lighter: #EFF9F2;
$brand-success-light: #A3DDB0;
$brand-success-dark: #327d43;
$brand-warning: #f90;
$brand-warning-lighter: #FFFBF1;
$brand-warning-light: #FFCC4D;
$brand-warning-dark: #FF7E00;
$brand-danger: #FF5050;
$brand-danger-lighter: #FEEDED;
$brand-danger-light: #FF9696;
$brand-danger-dark: #DB2E2E;

$black: #000;
$grey-darker: #282828;
$grey-dark: #333;
$grey: #555;
$grey-medium: #777;
$grey-medium-light: #999;
$grey-medium-lighter: #ccc;
$grey-light: #eee;
$grey-lighter: #f2f2f2;
$grey-lightest: #f5f5f5;
$white: #fff;

// Header & nav
$brand-header-bg: $brand-primary;
$brand-header-height: 100px;
$brand-header-margin: 146px;
/*header height + navbar height */
$brand-logo-height: 50px;
$brand-logo-margin: 8px;

$brand-nav-bg: $brand-secondary;
$brand-nav-bg-active: $brand-secondary-light;
$brand-nav-color: $grey-lighter;
$brand-nav-color-active: $white;

// Scaffolding
$brand-content-bg: $grey-lightest;
$brand-head-bg: $white;
$brand-row-hover: $grey-lightest;
$border-color: #ddd;
$border-color-light: $grey-light;
$border-color-dark: $grey-medium-lighter;
$panel-bg: $white;
$panel-head-bg: $white;

$table-border: $grey-light;
$paging-bg: $grey-lightest;
$footer-bg: $grey;
$footer-color: $white;

// Typography
$brand-font: 'Plus Jakarta Sans',Helvetica,Arial,sans-serif;
$brand-font-color: $grey-darker;
$brand-link: $brand-secondary-dark-aqua;
$brand-link-hover: $brand-secondary-navy;
$brand-page-title: $brand-secondary;
$headings-font-family: $brand-font;

$font-size-base: 12.5px;
$brand-font-base: $font-size-base;
$font-size-tiny: 10.5px;
$font-size-small: 11.5px;
$font-size-medium: 13px;
$font-size-large: 15px;
$font-size-larger: 16px;

$font-size-h1: 22px;
$font-size-h2: 20px;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: 15px;
$font-size-h6: 14px;

//border-radius
$border-radius-base: 10px; //small containers
$border-radius-large: 12px; //panels and main content
$border-radius-small: 7px; //buttons
$border-radius-x-small: 4px; //buttons sm/xs


