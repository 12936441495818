﻿body {
    font-family: $brand-font;
    font-size: $brand-font-base;
    color: $brand-font-color;
    background-color: $brand-content-bg;
    height: auto;
}

a {
    color: $brand-link;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $brand-link-hover;
        text-decoration: none;
    }

    &.inactive {
        color: $grey-medium-light;
    }
}

h1 {
    font-size: $font-size-h1;

    &.title {
        color: $brand-primary;
    }
}

h2 {
    font-size: $font-size-h2;
}

h3 {
    font-size: $font-size-h3;
}

h4 {
    font-size: $font-size-h4;
}

h5 {
    font-size: $font-size-h5;
}

h6 {
    font-size: $font-size-h6;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    font-family: $headings-font-family;
    line-height: 1.1;
}

i {
    color: $grey-medium-light;
}

.lead {
    font-size: $font-size-larger;
}

.divider {
    border-top: 1px dashed $border-color;
    margin-top: 20px;
    padding-top: 15px;
}

.portal {
    height: auto;
}

.tile-icon {
    font-size: 5rem;

    &:hover {
        color: $brand-primary;
    }
}

#nprogress {
    .bar,
    .spinner {
        z-index: 1050;
    }
}

.modal-header {
    font-size: 18px;

    .btn-close {
        margin: 0;
        padding: 0.375rem 0.75rem;
        font-size: 1.25rem;
    }
}

.modal-open {
    display: block;
}

.modal-close {
    display: none;
}

.grecaptcha-badge {
    opacity: 0;
}