/* BUTTONS */
$btn-font-size: $font-size-h6; //15px
$btn-font-size-sm: $font-size-base; //13px
$btn-font-weight: 600;

$btn-light-color: $grey-darker;
$btn-light-bg: #ebebeb;

$btn-primary-color: $white;
$btn-primary-bg: $brand-primary;

$btn-success-color: $white;
$btn-success-bg: $brand-success;

$btn-info-color: $white;
$btn-info-bg: $brand-info;

$btn-warning-color: $white;
$btn-warning-bg: $brand-warning;
$btn-warning-bg-hover: $brand-warning-dark;

$btn-danger-color: $white;
$btn-danger-bg: $brand-danger;
$btn-danger-bg-hover: $brand-danger-dark;

$btn-link-disabled-color: $grey-medium;


.btn {
	padding: 0.42rem 1.75rem;
	border-radius: $border-radius-small;
	border-width: 0;
	font-size: $btn-font-size;
	font-family: $headings-font-family;
	font-weight: 500;
	white-space: nowrap;

	&:active:focus,
	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled).active:focus {
		box-shadow: none;
	}
}

.btn-sm,
.btn-xs {
	padding: 0.32rem 1.25rem;
	border-radius: $border-radius-x-small;
	font-size: $btn-font-size-sm;
}

.btn.btn-link {
	padding: 0.35rem 0.75rem;
}

.btn-reset-padding {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.btn i {
  color: $white;
}

.btn-close {
	box-sizing: unset;
	width: unset;
	height: unset;
	padding: 0;
	color: unset;
	background: none;
	border-radius: 0;
	opacity: 1;
}

.btn-close {
	i {
		color: $grey-medium-light;
		padding: 0.125rem;
	}

	&:hover i,
	&:active i,
	&:focus i,
	&:not(:disabled):not(.disabled):active i,
	&:not(:disabled):not(.disabled).active i {
		color: $brand-danger;
		box-shadow: none;
	}
}


.btn-icon.btn-sm,
.btn-group-sm>.btn-icon.btn {
  height: 28px;
}

.btn-success {
	color: $white;
	background: $brand-success;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $white;
		background: $brand-success-dark;
		box-shadow: none;
	}

	&:disabled,
	&.disabled,
	&[disabled] {
		color: $white;
		background: $brand-success;
		opacity: .5;
	}
}

.btn-info {
	color: $btn-info-color;
	background: $brand-primary;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $btn-info-color;
		background: $brand-secondary;
		box-shadow: none;
	}

	&:disabled,
	&.disabled,
	&[disabled] {
		color: $btn-info-color;
		background: $brand-secondary;
		opacity: .6;
	}

	.show>&.dropdown-toggle {
		color: $btn-info-color;
		background: $brand-primary;
		border-color: transparent;
	}
}

.btn-light {
	color: $btn-light-color;
	background: $btn-light-bg;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $btn-light-color;
		background: $grey-medium-lighter;
		box-shadow: none;
	}

	&:disabled,
	&.disabled,
	&[disabled] {
		color: $btn-light-color;
		background: $grey-lightest;
		opacity: 1;
	}
}

.btn-warning {
	color: $btn-warning-color;
	background-color: $btn-warning-bg;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show>&.dropdown-toggle {
		color: $white;
		background-color: $btn-warning-bg-hover;
		box-shadow: none;

		i {
			color: $btn-warning-color;
		}
	}
}

.btn-link {
	color: $brand-link;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $brand-link-hover;
		text-decoration: none;
	}
}


.icon-buttons .btn {
  color: $grey-medium-light;
  background-color: transparent;
  border: 0 none;
  font-size: $font-size-base;
  padding: 0 6px 1px;

  &.btn-primary:hover {
    color: $brand-primary;
  }

  &.btn-info:hover {
    color: $brand-secondary;
  }

  &.btn-danger:hover {
    color: $brand-danger;
  }
}



/* ICON BUTTONS */

.btn-icon {
  padding: 2px 4px;
	background-color: transparent;
	background-image: none;
	border: 0 none;

  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    background-color: transparent;
    box-shadow: none;
  }

  &:disabled {
    background-image: none;
    background-color: transparent;
    box-shadow: none;
  }

  i {
    color: $grey-medium-light;
    line-height: 1;
  }

  &.btn-success:hover,
  &.btn-success:hover i {
    color: $brand-success !important;
  }

  &.btn-success i.fa-plus {
    color: $brand-success !important;
  }

  .show>&.btn-success.dropdown-toggle {
    color: $brand-success;
    background: transparent;

    & i,
    &:hover i {
      color: $brand-success;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-info:hover i {
    color: $brand-secondary;
  }

  .show>&.btn-info.dropdown-toggle {
    color: $brand-secondary;
    background: transparent;

    & i,
    &:hover i {
      color: $brand-secondary;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-primary:hover i {
    color: $brand-primary;
  }

  .show>&.btn-info.dropdown-toggle {
    color: $brand-primary;
    background: transparent;

    & i,
    &:hover i {
      color: $brand-primary;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-warning:hover i {
    color: $brand-warning;
  }

  &.btn-danger:hover i {
    color: $brand-danger;
  }

  &.border-bottom {
    border-bottom: 1px solid $border-color !important;
    /* need specificity to override bootstrap */
  }
}