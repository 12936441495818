footer {
    height: 34px;
    background-color: $footer-bg;
    color: $footer-color;
    font-weight: 400;
    padding: 8px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 4;
}