@import "base-variables.scss";
@import "../rlpp-style.scss";

/* DIV STYLES */
.page-header .rl-account-dropdown .btn {
    color: $white;
}

// Panels
.card-title h3 {
    color: $brand-secondary-light;
    font-size: 24px;
}

.card-grid .card .card-img-top a {
    color: $brand-secondary-light;
}


// Tables
.rl-table thead tr th {
    background-color: $brand-tertiary;
}