/* CONTENT */

.app-content {
    margin-top: $brand-header-margin;
}

.modal-content {
    color: $brand-font-color;
}

.section-head {
    background-color: $brand-head-bg;
    color: $brand-primary;
    margin: 0 -12px 7px;
    min-height: 30px;

    h1 {
        color: $brand-primary;
        font-size: $font-size-h1;
        font-weight: 400;
        margin: 0;
    }

    .form-control {
        height: 32px;
        background-color: transparent;
    }

    .view-selector {
        margin: 6px 0 0;

        i {
            font-size: 1.25rem;
        }

        .active i {
            color: $brand-secondary-light;
        }
    }
}

.img-container {
    background-color: $brand-primary;
    padding: 5px 10px;
    height: 45px;

    img {
        max-height: 30px;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 80%;
    }
}

@media (min-width: 992px) {
    .card-h-100 {
        height: 100%;
    }

    .div-logo {
        border-right: 1px solid $border-color;
    }
}


/* STOREFRONT */

.sf .home {
    h1 {
        font-size: 2rem;
    }

    .mb-3 {
        .form-control {
            height: 60px;
            font-size: 18px;
            border: 2px solid $brand-success;
        }

        .btn {
            padding: 12px 18px;
            font-size: 24px;
        }
    }

    .border-top {
        border-top: 2px solid $brand-secondary !important;
        padding-top: 1rem;
    }
}

// LOGIN
.login-title {
    position: relative;
    top: -50px;
    margin-bottom: 40px;
    color: $grey-darker;
    font-size: 2.2rem;
}

.login-step-container {
    width: 600px;
    margin: 0 auto;
    background: $white;
    border-radius: 13px;
    box-shadow: 0 12px 18px rgba(0, 0, 0, 0.3);
    border: none;

    .login-step-title {
        text-align: center;
        background-color: $brand-secondary;
        border-radius: 13px 13px 0 0;

        h1 {
            color: #fff;
            font-size: 2.5rem;
            margin: 1.5rem 0;
        }
    }

    .login-step-body {
        padding: 3rem;
    }
}

// DEALS/REQUESTS
.entity-detail-bar {
    background-color: $white;
    height: 45px;
    white-space: nowrap;
    padding: 0 15px;

    .segment {
        display: inline-block;
        padding-top: 6px;
        margin-right: 30px;

        .header {
            font-size: $font-size-tiny;
            text-transform: uppercase;
            display: block;
            line-height: .9em;
        }

        .value {
            font-size: $font-size-small;
            display: block;
            font-weight: 600;
            line-height: 1.25em;
            margin-top: 4px;
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.scroll-horiz {
    overflow: hidden;
    overflow-x: auto;
}

/* Color Picker */
.color-picker-combo {
    display: flex;
    align-items: center;

    input:focus {
        border-radius: 0;
        outline: none;
    }

    .color-picker-box {
        position: relative;
        border: 1px solid $black;
        overflow: hidden;
        height: 28px;

        input[type="color"] {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            background: none;
            border: 0;
            cursor: pointer;
            height: 28px;
            padding: 0;
            width: 28px;
        }
    }


    ::-webkit-color-swatch-wrapper {
        padding: 0;
    }

    ::-webkit-color-swatch {
        border: 0;
        border-radius: 0;
    }

    ::-moz-color-swatch,
    ::-moz-focus-inner {
        border: 0;
    }

    ::-moz-focus-inner {
        padding: 0;
    }

    .color-picker-input {}
}

.no-items-found {
    font-size: 15px;
    color: $grey-medium;
    font-weight: 300;

    i {
        color: $grey-medium-light;
        font-size: 20px;
    }
}

.record-summary {
    .record-summary-title {
        text-transform: uppercase;
        font-size: 0.7692em;
        font-family: $headings-font-family;
    }

    .record-summary-status {
        display: block;
        font-size: 13px;
    }
}


/*---------------------------*\
* #WIZARDS
\*---------------------------*/

.rl-wizard .horizontal.small {
    ul.steps-indicator {
        padding: 0;

        li {
            margin: 24px 0 10px 0;

            .step-indicator,
            &.default .step-indicator,
            &.optional .step-indicator,
            &.navigable .step-indicator {
                background-color: $grey-medium-lighter !important;
            }

            &.done .step-indicator {
                background-color: $brand-primary !important;
            }

            &.current .step-indicator,
            &.editing .step-indicator {
                background-color: $brand-success !important;
            }

            /* hides wizard step if there is only one step */
            &:only-of-type {
                display: none;
            }
        }
    }
}