/* FORMS */

.form-control {
    font-size: $brand-font-base;
    border-radius: 0;
    border: 0 none;
    border-bottom: 1px solid $border-color;

    &:focus {
        box-shadow: none;
        border-bottom: 2px solid $brand-success;
    }
}

.form-select {
  font-size: $brand-font-base;

  &:focus {
    box-shadow: none;
    border-bottom: 2px solid $brand-success;
}
}

.input-group-prepend {
    color: $grey-medium-lighter;
    border-bottom: 1px solid $border-color;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;

    i {
        align-self: center;
    }

    .btn {
        padding: 0;
        color: $grey-medium-light;
    }
}

.checkbox.form-control-static label {
    margin: 9px 0 0;
}

.has-error {
    .help-block,
    .control-label {
        color: $brand-danger;
    }

    .form-control {
        border-color: $brand-danger;
    }
}

.has-warning {
		input[type="text"],
		input[type="number"],
		select.form-control,
		select.form-select,
		textarea.form-control {
				color: $brand-warning-dark;
				border-bottom: 1px solid $brand-warning-dark;

				&:focus {
						border-bottom: 2px solid $brand-warning;
				}
		}

		.help-block {
				color: $brand-warning-dark;
		}

		.input-group-text,
		.input-group-append .input-group-text {
				color: $brand-warning;
				border-bottom: 1px solid $brand-warning-dark;
		}
}

.alert-danger {
    background-color: lighten($brand-danger, 25%);
    border-color: $brand-danger;
    color: $brand-danger;
}

.text-length {
    font-size: 12px;
    color: #999;
}

#ui-datepicker-div.ui-datepicker {
    position: relative;
    z-index: 1050 !important;
}

// override filepicker zindex
.fp__overlay {
    z-index: 2000 !important;
}


.tree-list-control {
    .scroll {
        max-height: 250px;
        overflow-y: auto;
    }
}

.pp-tree-list,
.pp-tree-list ul {
    list-style: none;
    margin: 0;
    padding: 0;


    .tree-row {
        display: flex;
        padding: 4px 0 0 5px;

        >.arrow-btn {
            cursor: pointer;
            flex-shrink: 0;
            align-self: flex-start;
            padding-right: 5px;
            margin-top: 0.3em;
            min-width: 12px;
        }

        >.box {
            flex-shrink: 0;
            align-self: flex-start;
        }

        >.title {
            padding-left: 5px;
            flex-grow: 1;
            align-self: flex-start;
            cursor: pointer;
            font-size: .95em;
            line-height: 1.1em;
            padding: 2px 4px 8px 4px;

            &.match {
                font-weight: 700;
            }
        }
    }

    .help-block {
        font-size: 1em;
    }
}

sf-address {
    display: block;
}
