/* PAGINATION */
.pagination {
    margin-bottom: 0;
}

.page-item.active .page-link {
    background-color: $brand-primary;
    border-color: $brand-primary;
}

.page-link {
    color: $brand-primary;
    background-color: transparent;
    border: 0 none;
}

.page-item.disabled .page-link {
    background-color: transparent;
}

.pager-dropdown {
    cursor: pointer;
    z-index: 9999 !important;
}

.jump-to-page {
    padding: 3px;
    text-align: center;
}