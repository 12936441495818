﻿/* CHAR DATA */

.pp-char-data-table {
    width: 100%;

    .pp-char-data-group {
        .pp-char-data-group-label {
            min-height: 20px;
            margin: 0 0 6px;

            span {
                font-size: 16px;
                font-weight: 500;
                color: $grey-darker;
                max-width: 100%;
                padding: 0 10px 0 0;
                margin: 5px 0;
            }
        }

        .pp-char-data-elements {
            display: table;
            width: 100%;
            margin-bottom: 15px;

            .panel-meta & {
                margin-bottom: 0;
            }

            .pp-char-data-element {
                display: table-row;
                vertical-align: top;
                width: 100%;
                -moz-transition: background-color ease-out 0.2s;
                -o-transition: background-color ease-out 0.2s;
                -webkit-transition: background-color ease-out 0.2s;
                transition: background-color ease-out 0.2s;

                &:hover, &:active {
                    background-color: $brand-row-hover;
                    -moz-transition: background-color ease-out 0.2s;
                    -o-transition: background-color ease-out 0.2s;
                    -webkit-transition: background-color ease-out 0.2s;
                    transition: background-color ease-out 0.2s;
                }

                .pp-char-data-element-label {
                    display: table-cell;
                    vertical-align: top;
                    width: 30%;
                    text-align: right;
                    padding: 8px 8px 8px 28px;
                    line-height: 1.4em;
                    background: $brand-tertiary;
                }

                &.has-error .pp-char-data-element-control .pp-char-data-element-edit {
                    input[type=text].form-control, select.form-control, textarea.form-control, .tree-list-control.form-control {
                        background-color: #fffafa !important;
                    }
                }

                .pp-char-data-element-control {
                    display: table-cell;
                    width: 70%;
                    margin-bottom: 0;

                    .pp-char-data-table-errors {
                        display: block;
                        margin-left: 10px;
                    }

                    .pp-char-data-element-display {
                        padding: 8px 5px 8px 20px;
                        line-height: 1.4em;
                        -ms-word-break: break-word;
                        word-break: break-word;
                    }

                    .tree-list-control.form-control {
                        height: auto;
                    }

                    .pp-char-data-element-edit {
                        textarea.form-control {
                            height: 75px;
                        }

                        // @media(min-width: @screen-md-min) {
                        //     .input-group {
                        //         width: 50%;

                        //         input[type=text].form-control, select.form-control, textarea.form-control {
                        //             width: 100%;
                        //         }
                        //     }

                        //     input[type=text].form-control, select.form-control, textarea.form-control, .tree-list-control {
                        //         width: 50%;
                        //     }
                        // }
                    }

                    .checkbox-null {
                        opacity: 0.4;
                        filter: alpha(opacity=40);
                    }

                    .text-padding {
                        padding-top: 6px;
                    }
                }
            }
        }
    }
}

.pp-char-data-element-edit {
    padding: 2px 10px;
}

.contacts-group-label {
    background-image: none !important;
}
