/* BUTTONS */
$btn-font-weight: 600;
$btn-default-color: $grey-dark;
$btn-default-bg: $white;
$btn-link-disabled-color: $grey-medium;


.btn {
  border-radius: 0;
  border-width: 0;
  font-family: $headings-font-family;
	white-space: nowrap;

  &:active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
    border: 0 none;
  }
}

.btn i {
  color: $white;
}

.btn-close {
  box-sizing: unset;
  width: unset;
  height: unset;
  padding: 0;
  color: unset;
  background: none;
  border-radius: 0;
  opacity: 1;
}

.btn-close:hover i,
.btn-close:active i,
.btn-close:focus i,
.btn-close:not(:disabled):not(.disabled):active i,
.btn-close:not(:disabled):not(.disabled).active i,
.btn-remove:hover i,
.btn-remove:active i,
.btn-remove:focus i,
.btn-remove:not(:disabled):not(.disabled):active i,
.btn-remove:not(:disabled):not(.disabled).active i {
  color: #ea1c1c;
  background: linear-gradient(#fe2425, #bf0202);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  box-shadow: none;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}

.btn-icon.btn-sm,
.btn-group-sm>.btn-icon.btn {
  height: 28px;
}

.btn-success {
  color: $white;
  background: linear-gradient(to bottom right, $brand-success-light, $brand-success-dark);

  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show>&.dropdown-toggle {
    box-shadow: none;
    color: $white;
		background: linear-gradient(to bottom right, $brand-success-dark, $brand-success-dark);
  }
}

.btn-info {
  color: $white;
	background: linear-gradient(to bottom right, $brand-secondary-light, $brand-secondary-dark);

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $white;
		background: linear-gradient(to bottom right, $brand-secondary-dark, $brand-secondary-dark);
		box-shadow: none;
	}

	&:disabled,
	&.disabled {
		color: $white;
		border-color: $grey-medium;
	}

	.show>&.dropdown-toggle {
		color: $white;
		background: linear-gradient(to bottom right, $brand-secondary-dark, $brand-secondary-dark);
		border-color: transparent;
	}
}

.btn-warning {
	color: $white;
	background: linear-gradient(to bottom right, $brand-warning-light, $brand-warning-dark);

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show>&.dropdown-toggle {
		color: $white;
		background: linear-gradient(to bottom right, $brand-warning, $brand-warning-dark);
		box-shadow: none;
	}
}

.btn-link {
	color: $brand-link;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $brand-primary;
		text-decoration: none;
	}
}

.btn-close i {
  color: $grey-medium-light;
  padding: 0.125rem;
}


.icon-buttons .btn {
  color: $grey-medium-light;
  background-color: transparent;
  border: 0 none;
  font-size: $font-size-base;
  padding: 0 6px 1px;

  &.btn-primary:hover {
    color: $brand-primary;
  }

  &.btn-info:hover {
    color: $brand-secondary;
  }

  &.btn-danger:hover {
    color: $brand-danger;
  }
}



/* ICON BUTTONS */

.btn-icon {
  background-color: transparent;
  background-image: none;
  color: $grey-medium-light;
  border: 0 none;
  padding: 3px 4px;

  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    background-color: transparent;
    box-shadow: none;
  }

  &:disabled {
    background-image: none;
    background-color: transparent;
    box-shadow: none;
  }

  i {
    color: $grey-medium-light;
    line-height: 1;
  }

  &.btn-success:hover,
  &.btn-success:hover i {
    color: $brand-success !important;
  }

  &.btn-success i.fa-plus {
    color: $brand-success !important;
  }

  .show>&.btn-success.dropdown-toggle {
    color: $brand-success;
    background: transparent;

    & i,
    &:hover i {
      color: $brand-success;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-info:hover i {
    color: $brand-secondary;
  }

  .show>&.btn-info.dropdown-toggle {
    color: $brand-secondary;
    background: transparent;

    & i,
    &:hover i {
      color: $brand-secondary;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-primary:hover i {
    color: $brand-primary;
  }

  .show>&.btn-info.dropdown-toggle {
    color: $brand-primary;
    background: transparent;

    & i,
    &:hover i {
      color: $brand-primary;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-warning:hover i {
    color: $brand-warning;
  }

  &.btn-danger:hover i {
    color: $brand-danger;
  }

  &.border-bottom {
    border-bottom: 1px solid $border-color !important;
    /* need specificity to override bootstrap */
  }
}