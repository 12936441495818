/* DROPDOWNS */

.dropdown-menu {
	font-size: $font-size-base;
	border-radius: 0;
	/*setting this globally to ensure menus aren't too long*/
	max-height: 350px;
	overflow: auto;
	z-index: 1060;

	a {
		cursor: pointer;
	}

	/* Remove padding on dropdowns that have tabs */
	.tab-content {
		padding: 0;
	}

    .dropdown-item {
        font-size: $brand-font-base;
        padding: 0.25rem 0.75rem;
    }
}
.hide-caret.dropdown-toggle::after {
    display: none;
}

.dropdown-header {
    color: $grey-medium;
    font-size: $font-size-small;
    text-transform: uppercase;
    margin: 0;
    padding: 0.5em;
}


.workflow-action {
    min-width: 300px;
    min-height: 100px;
    max-height: 450px;
    overflow: auto;
    border-radius: 0;

    li a {
        display: flex;
        align-items: center;
        white-space: nowrap;

        .btn {
            visibility: hidden;
            margin-left: 10px;
            align-self: flex-end;
        }

        &:hover {
            .btn {
                visibility: visible;
            }
        }
    }
}

.list-group-flush .list-group-item {
	color: $grey;
	text-align: left;
	padding: 0.25rem 0.5rem;
	border: 0 none;

	&:hover {
		color: $brand-link;
		background-color: $brand-row-hover;
	}
}

.list-group-flush .list-group-item.disabled,
.list-group-flush .list-group-item:disabled {
	color: $grey-medium-light;
}

/* DROPDOWN MENUS with TABS */

.dropdown-menu .tab-content .dropdown-header {
	padding: 0.5rem;
	margin-top: 0.75rem;
}

.dropdown-item {
	&:visited {
		color: $grey;
	}

	&.disabled {
		color: $grey-medium-light !important;
	}
}

.nav-link.active i,
.nav-link:hover i {
    color: $brand-primary;
}