/* DROPDOWNS */

.dropdown-menu {
    .dropdown-item {
        font-size: $brand-font-base;
        padding: 0.25rem 0.75rem;
    }
}
.hide-caret.dropdown-toggle::after {
    display: none;
}

.dropdown-header {
    color: $grey-medium;
    font-size: $font-size-small;
    text-transform: uppercase;
    margin: 0;
    padding: 0.5em;
}


.workflow-action {
    min-width: 300px;
    min-height: 100px;
    max-height: 450px;
    overflow: auto;
    border-radius: 0;

    li a {
        display: flex;
        align-items: center;
        white-space: nowrap;

        .btn {
            visibility: hidden;
            margin-left: 10px;
            align-self: flex-end;
        }

        &:hover {
            .btn {
                visibility: visible;
            }
        }
    }
}