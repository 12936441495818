@import "base-variables.scss";

// Header & nav
$brand-header-bg: $brand-primary;
$brand-header-height: 100px;
$brand-header-margin: 146px;
/*header height + navbar height */
$brand-logo-height: 50px;
$brand-nav-bg: $brand-secondary;
$brand-nav-bg-active: $brand-secondary-light;

// Scaffolding
$brand-link-hover: $brand-secondary;
$brand-page-title: $brand-secondary;
$brand-content-bg: $grey-lightest;
$brand-head-bg: $white;

@import "../rlpp-style.scss";


/* DIV STYLES */
.page-header .rl-account-dropdown .btn {
    color: $white;
}

.pp-char-data-table .pp-char-data-group .pp-char-data-group-label span {
    color: $brand-secondary-light;
}


// Panels
.card-title h3 {
    color: $brand-secondary-light;
    font-size: 24px;
}

.card-grid .card .card-img-top a {
    color: $brand-secondary-light;
}


// Tables
.rl-table thead tr th {
    background-color: $brand-tertiary;
}