/* HEADER */

.page-header {
    background-color: $brand-header-bg;

    .page-header__brand {
        height: $brand-header-height;

        img {
            margin: $brand-logo-margin;
            max-height: $brand-logo-height;
        }
    }
}

.page-header__nav {
    background-color: $brand-nav-bg;

    .user__tools div>a {
        color: $brand-nav-color;
    }
}

.navbar {
    background-color: $brand-nav-bg;
    border-color: $brand-nav-bg;
    padding: 0;

    .navbar-nav {
        .nav-item {
            .nav-link {
                padding: 0.75rem 1.25rem;
                color: $brand-nav-color;
                font-size: $font-size-medium;

                &:hover {
                    color: $brand-nav-color-active;
                }
            }

            &.active {
                background-color: $brand-nav-bg-active;

                .nav-link {
                    color: $brand-nav-color-active;

                    &:hover,
                    &:focus {
                        color: $brand-nav-color-active;
                    }
                }
            }
        }
    }

    .navbar-toggler:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }

}

.rl-account-dropdown {
    a {
        font-size: $font-size-small;
    }
}

.div-padding {
    padding-right: 15px;
}
