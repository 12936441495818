:root {
  --brand-primary-color: #1e003e;
  --brand-primary-medium-dark-color: #{lighten(#1e003e, 10%)};
  --brand-secondary-color: #58419b;
  --brand-secondary-light-color: #7861b8;
  --brand-secondary-dark-color: #{darken(#412c80, 3%)};
  --brand-tertiary-color: #efeffd;
}

/* VARIABLES */
$brand-primary: var(--brand-primary-color);
$brand-primary-medium-dark: var(--brand-primary-medium-dark-color);
$brand-secondary: var(--brand-secondary-color);
$brand-secondary-dark: var(--brand-secondary-dark-color);
$brand-secondary-light: var(--brand-secondary-light-color);
$brand-tertiary: var(--brand-tertiary-color);

$brand-info: $brand-secondary;
$brand-success: #06ad21;
$brand-success-lighter: #ebf5ee;
$brand-success-light: #59c16f;
$brand-success-dark: #019218;
$brand-warning: #f90;
$brand-warning-lighter: #fdf6de;
$brand-warning-light: #fc0;
$brand-warning-dark: #ff7e00;
$brand-danger: #ea1c1c;
$brand-danger-lighter: #feeded;
$brand-danger-light: #fe2425;
$brand-danger-dark: #bf0202;

$black: #000;
$grey-darker: #222;
$grey-dark: #333;
$grey: #555;
$grey-medium: #777;
$grey-medium-light: #999;
$grey-medium-lighter: #ccc;
$grey-light: #eee;
$grey-lighter: #f2f2f2;
$grey-lightest: #f5f5f5;
$white: #fff;

// Header & nav
$brand-header-bg: $white;
$brand-header-height: 52px;
$brand-header-margin: 97px;
/*header height + navbar height */
$brand-logo-height: 36px;
$brand-logo-margin: 8px;

$brand-nav-bg: $brand-primary;
$brand-nav-bg-active: #636e95;
$brand-nav-color: $grey-lighter;
$brand-nav-color-active: $white;

// Scaffolding
$brand-content-bg: #f4f8fb;
$brand-head-bg: #e9eff4;
$brand-row-hover: $grey-lightest;
$border-radius-base: 0;
$border-color: #ddd;
$border-color-light: $grey-light;
$border-color-dark: $grey-medium-lighter;
$panel-bg: $white;
$panel-head-bg: $white;

$table-border: $grey-light;
$paging-bg: $grey-lightest;
$footer-bg: $grey;
$footer-color: $white;

// Typography
$brand-font: 'Open Sans', sans-serif;
$brand-font-color: $grey;
$brand-link: #4265c3;
$brand-link-hover: $brand-primary;
$brand-page-title: $brand-primary;
$headings-font-family: 'Nunito Sans','Open Sans',Arial,Helvetica,sans-serif;

$font-size-base: 13px;
$brand-font-base: $font-size-base;
$font-size-tiny: ceil(($font-size-base * 0.8461)); // 11px
$font-size-small: ceil(($font-size-base * 0.9175)); // 12px
$font-size-medium: ceil(($font-size-base * 1.0769)); // 14px
$font-size-large: ceil(($font-size-base * 1.2307)); // 16px
$font-size-larger: ceil(($font-size-base * 1.3846)); // 18px

$font-size-h1: ceil(($font-size-base * 1.8461)); // 24px
$font-size-h2: ceil(($font-size-base * 1.6154)); // 21px
$font-size-h3: ceil(($font-size-base * 1.5384)); // 20px
$font-size-h4: $font-size-larger; // 18px
$font-size-h5: $font-size-large; // 16px
$font-size-h6: $font-size-medium; // 14px
