/* CARDS */

/* Catalog card grid */
.card-grid {
    .card-img-top {
        min-height: 226px;
        padding: 0.5rem;

        img {
            max-width: 100%;
            max-height: 210px;
        }

        &.noimage {
            min-height: 1px;
        }
    }

    .card-body {
        min-height: 55px;
        padding: 0.5rem 1rem;
        text-align: center;

        h3 {
            font-size: $font-size-h6;
            font-weight: 400;
            margin: 0;
        }

    }
}

.card {
    .card-title {
        padding: 1.25rem 1.25rem 0;
        margin-bottom: 0;
    }

}

.card-meta {
    .keyart {
        img {
            max-width: 350px;
            max-height: 400px;
        }
    }

    .pp-char-data-table {
        max-height: 400px;
        overflow-y: auto;
        margin-bottom: 0;

        .pp-char-data-group {
            .pp-char-data-elements {
                padding: 0 15px;
            }
        }
    }
}


// STOREFRONT
.sf {
    .card {
        .keyart {
            img {
                max-width: 350px;
                max-height: 400px;
            }
        }

        .pp-char-data-table {
            margin-bottom: 0;
        }
    }
    
    .catalog-list--child {
        width: 300px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .keyart {
            img {
                height: 140px;
                width: 140px;
                object-fit: cover;
                margin: 5px;
            }
        }
    }
}