/* TABLES */

.rl-table {
    margin-bottom: 0;
    border: 1px solid $table-border;
    background-color: $white;

    tbody {
        &>tr {
            &>td {
                border-bottom: 1px solid $table-border;
                padding: 4px 8px;
                transition: background-color 0.5s ease;

                p {
                    margin: 0;
                }
            }

            &:hover>td {
                background-color: $brand-row-hover !important;
            }
        }
    }

    tbody tr,
    thead tr {
        height: 30px;
    }

    thead tr th {
        background-color: $brand-head-bg;
        border-bottom: 1px solid $border-color;
        font-size: $brand-font-base;
        font-weight: 600;
        vertical-align: middle;
        padding: 8px;
    }

    thead>tr>th,
    tbody>tr>td,
    tbody>tr td div,
    tbody>tr td span {
        white-space: nowrap;
        //max-width: 250px; //not sure why we need this but it probably needs to be rewritten as it's too broad currently
        overflow: hidden;
        vertical-align: middle;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
}

.table-paginate {
    background-color: $paging-bg;
    border-top: 1px solid $table-border;
    padding: 2px 12px;
    height: 37px;
    min-height: 37px;
}

.filter-list {
    max-height: 150px;
    max-width: 250px;
    overflow-y: auto;
    margin: 0;

    .dropdown-item {
        white-space: normal;
        font-weight: normal;
    }
}

.scroll-wrap {
    position: relative;
    overflow: hidden;

    &.shadow:after {
        position: absolute;
        top: 0;
        left: 100%;
        width: 50px;
        height: 100%;
        border-radius: 10px 0 0 10px / 50% 0 0 50%;
        box-shadow: -4px 0 8px rgba(0, 0, 0, 0.05);
        content: '';
    }

    &>.scrollable {
        width: 100%;
        overflow-y: auto;
        _overflow: auto;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 3px solid $white;
            background-color: $grey-light;
            cursor: pointer;
        }
    }
}

/* char style on normal table */
.table-char-data {
    width: 100%;

    tbody {
        th {
            vertical-align: top;
            width: 30%;
            text-align: right;
            padding: 8px 8px 8px 28px;
            line-height: 1.4em;
            background-color: $brand-head-bg;
            border: 0 none;
        }

        td {
            border: 0 none;
            padding: 8px 5px 8px 20px;
            line-height: 1.4em;
            -ms-word-break: break-word;
            word-break: break-word;
        }

        tr {
            vertical-align: top;
            -moz-transition: background-color ease-out 0.2s;
            -o-transition: background-color ease-out 0.2s;
            -webkit-transition: background-color ease-out 0.2s;
            transition: background-color ease-out 0.2s;

            &:hover,
            &:active {
                background-color: $brand-row-hover;
                -moz-transition: background-color ease-out 0.2s;
                -o-transition: background-color ease-out 0.2s;
                -webkit-transition: background-color ease-out 0.2s;
                transition: background-color ease-out 0.2s;

                th {
                    background-color: $brand-row-hover;
                }
            }
        }
    }
}


/* SF tables */
.sf {
    .catalog-list {
        td img {
            max-width: 150px;
            max-height: 250px;
        }

        .rl-table tbody>tr td {
            padding: 8px;

            div {
                max-width: 100%;
                white-space: normal;
                overflow: visible;
            }
        }
    }

    .group-header {
        min-height: 20px;
        margin: 8px 0;
        background-image: linear-gradient(to bottom, rgba(1, 1, 1, 0) 50%, #dddddd 50%);
        background-size: 2px 2px;
        background-repeat: repeat-x;
        background-position-y: 50%;

        span {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            max-width: 100%;
            background-color: white;
            padding-right: 10px;
            margin: 5px 0;
        }
    }
}


th {
    white-space: nowrap;

    .show {
        display: inline-block !important;
    }
}

.table-header-link {
	cursor: pointer;
	&:hover {
 	   text-decoration: underline;
    }
}

.hover-icons {
    visibility: hidden;
}

tr:hover .hover-icons {
    visibility: visible;
}
